export const INSURANCE_CFE_OPTIONS = [
    { value: 'YES', label: 'Oui' },
    { value: 'NO', label: 'Non' },
    { value: 'NOT_KNOWN', label: 'Je ne sais pas' }
];


export const NATIONALITY_OPTIONS = [
    { value: 'Afrique du Sud', label: 'Afrique du Sud'},
    { value: 'Afghanistan', label: 'Afghanistan'},
    { value: 'Albanie', label: 'Albanie'},
    { value: 'Algérie', label: 'Algérie'},
    { value: 'Allemagne', label: 'Allemagne'},
    { value: 'Andorre', label: 'Andorre'},
    { value: 'Angola', label: 'Angola'},
    { value: 'Arabie saoudite', label: 'Arabie saoudite'},
    { value: 'Argentine', label: 'Argentine'},
    { value: 'Arménie', label: 'Arménie'},
    { value: 'Australie', label: 'Australie'},
    { value: 'Autriche', label: 'Autriche'},
    { value: 'Azerbaïdjan', label: 'Azerbaïdjan'},
    { value: 'Bahamas', label: 'Bahamas'},
    { value: 'Bahreïn', label: 'Bahreïn'},
    { value: 'Bangladesh', label: 'Bangladesh'},
    { value: 'Barbade', label: 'Barbade'},
    { value: 'Bélarus', label: 'Bélarus'},
    { value: 'Belgique', label: 'Belgique'},
    { value: 'Bélize', label: 'Bélize'},
    { value: 'Bénin', label: 'Bénin'},
    { value: 'Bhoutan', label: 'Bhoutan'},
    { value: 'Birmanie', label: 'Birmanie'},
    { value: 'Bolivie', label: 'Bolivie'},
    { value: 'Bosnie-Herzégovine', label: 'Bosnie-Herzégovine'},
    { value: 'Botswana', label: 'Botswana'},
    { value: 'Brésil', label: 'Brésil'},
    { value: 'Brunéi Darussalam', label: 'Brunéi Darussalam'},
    { value: 'Bulgarie', label: 'Bulgarie'},
    { value: 'Burkina Faso', label: 'Burkina Faso'},
    { value: 'Burundi', label: 'Burundi'},
    { value: 'Cameroun', label: 'Cameroun'},
    { value: 'Canada', label: 'Canada'},
    { value: 'Cambodge', label: 'Cambodge'},
    { value: 'Chili', label: 'Chili'},
    { value: 'Chine', label: 'Chine'},
    { value: 'Chypre', label: 'Chypre'},
    { value: 'Colombie', label: 'Colombie'},
    { value: 'Comores', label: 'Comores'},
    { value: 'Congo', label: 'Congo'},
    { value: 'Corée du Nord', label: 'Corée du Nord'},
    { value: 'Corée du Sud', label: 'Corée du Sud'},
    { value: 'Costa Rica', label: 'Costa Rica'},
    { value: 'Côte d’Ivoire', label: 'Côte d’Ivoire'},
    { value: 'Croatie', label: 'Croatie'},
    { value: 'Cuba', label: 'Cuba'},
    { value: 'Danemark', label: 'Danemark'},
    { value: 'Djibouti', label: 'Djibouti'},
    { value: 'Dominique', label: 'Dominique'},
    { value: 'Égypte', label: 'Égypte'},
    { value: 'Émirats arabes unis', label: 'Émirats arabes unis'},
    { value: 'Équateur', label: 'Équateur'},
    { value: 'Érythrée', label: 'Érythrée'},
    { value: 'Espagne', label: 'Espagne'},
    { value: 'Estonie', label: 'Estonie'},
    { value: 'États-Unis d’Amérique', label: 'États-Unis d’Amérique'},
    { value: 'Éthiopie', label: 'Éthiopie'},
    { value: 'Fidji', label: 'Fidji'},
    { value: 'Finlande', label: 'Finlande'},
    { value: 'France', label: 'France'},
    { value: 'Gabon', label: 'Gabon'},
    { value: 'Gambie', label: 'Gambie'},
    { value: 'Géorgie', label: 'Géorgie'},
    { value: 'Ghana', label: 'Ghana'},
    { value: 'Grèce', label: 'Grèce'},
    { value: 'Grenade', label: 'Grenade'},
    { value: 'Guatemala', label: 'Guatemala'},
    { value: 'Guinée', label: 'Guinée'},
    { value: 'Guinée-Bissau', label: 'Guinée-Bissau'},
    { value: 'Guinée équatoriale', label: 'Guinée équatoriale'},
    { value: 'Guyana', label: 'Guyana'},
    { value: 'Haïti', label: 'Haïti'},
    { value: 'Honduras', label: 'Honduras'},
    { value: 'Hong Kong', label: 'Hong Kong'},
    { value: 'Hongrie', label: 'Hongrie'},
    { value: 'Île Maurice', label: 'Île Maurice'},
    { value: 'Îles Marshall', label: 'Îles Marshall'},
    { value: 'Îles Salomon', label: 'Îles Salomon'},
    { value: 'Inde', label: 'Inde'},
    { value: 'Indonésie', label: 'Indonésie'},
    { value: 'Iran', label: 'Iran'},
    { value: 'Irak', label: 'Irak'},
    { value: 'Irlande', label: 'Irlande'},
    { value: 'Islande', label: 'Islande'},
    { value: 'Israël', label: 'Israël'},
    { value: 'Italie', label: 'Italie'},
    { value: 'Jamaïque', label: 'Jamaïque'},
    { value: 'Japon', label: 'Japon'},
    { value: 'Jordanie', label: 'Jordanie'},
    { value: 'Kazakhstan', label: 'Kazakhstan'},
    { value: 'Kenya', label: 'Kenya'},
    { value: 'Kiribati', label: 'Kiribati'},
    { value: 'Kirghizistan', label: 'Kirghizistan'},
    { value: 'Kosovo', label: 'Kosovo'},
    { value: 'Koweït', label: 'Koweït'},
    { value: 'Laos', label: 'Laos'},
    { value: 'Lesotho', label: 'Lesotho'},
    { value: 'Lettonie', label: 'Lettonie'},
    { value: 'Liban', label: 'Liban'},
    { value: 'Libéria', label: 'Libéria'},
    { value: 'Libye', label: 'Libye'},
    { value: 'Liechtenstein', label: 'Liechtenstein'},
    { value: 'Lituanie', label: 'Lituanie'},
    { value: 'Luxembourg', label: 'Luxembourg'},
    { value: 'Macédoine', label: 'Macédoine'},
    { value: 'Madagascar', label: 'Madagascar'},
    { value: 'Malaisie', label: 'Malaisie'},
    { value: 'Malawi', label: 'Malawi'},
    { value: 'Maldives', label: 'Maldives'},
    { value: 'Mali', label: 'Mali'},
    { value: 'Malte', label: 'Malte'},
    { value: 'Maroc', label: 'Maroc'},
    { value: 'Mauritanie', label: 'Mauritanie'},
    { value: 'Mexique', label: 'Mexique'},
    { value: 'Micronésie', label: 'Micronésie'},
    { value: 'Moldavie', label: 'Moldavie'},
    { value: 'Monaco', label: 'Monaco'},
    { value: 'Monténégro', label: 'Monténégro'},
    { value: 'Mozambique', label: 'Mozambique'},
    { value: 'Namibie', label: 'Namibie'},
    { value: 'Nauru', label: 'Nauru'},
    { value: 'Népal', label: 'Népal'},
    { value: 'Nicaragua', label: 'Nicaragua'},
    { value: 'Niger', label: 'Niger'},
    { value: 'Nigéria', label: 'Nigéria'},
    { value: 'Norvège', label: 'Norvège'},
    { value: 'Nouvelle-Zélande', label: 'Nouvelle-Zélande'},
    { value: 'Oman', label: 'Oman'},
    { value: 'Ouganda', label: 'Ouganda'},
    { value: 'Ouzbékistan', label: 'Ouzbékistan'},
    { value: 'Pakistan', label: 'Pakistan'},
    { value: 'Palaos', label: 'Palaos'},
    { value: 'Panamá', label: 'Panamá'},
    { value: 'Papouasie-Nouvelle-Guinée', label: 'Papouasie-Nouvelle-Guinée'},
    { value: 'Paraguay', label: 'Paraguay'},
    { value: 'Pays-Bas', label: 'Pays-Bas'},
    { value: 'Pérou', label: 'Pérou'},
    { value: 'Philippines', label: 'Philippines'},
    { value: 'Pologne', label: 'Pologne'},
    { value: 'Polynésie Française', label: 'Polynésie Française'},
    { value: 'Porto Rico', label: 'Porto Rico'},
    { value: 'Portugal', label: 'Portugal'},
    { value: 'Qatar', label: 'Qatar'},
    { value: 'République centrafricaine', label: 'République centrafricaine'},
    { value: 'République démocratique du Congo', label: 'République démocratique du Congo'},
    { value: 'République dominicaine', label: 'République dominicaine'},
    { value: 'République tchèque', label: 'République tchèque'},
    { value: 'Roumanie', label: 'Roumanie'},
    { value: 'Royaume-Uni', label: 'Royaume-Uni'},
    { value: 'Russie', label: 'Russie'},
    { value: 'Rwanda', label: 'Rwanda'},
    { value: 'Saint-Kitts-et-Nevis', label: 'Saint-Kitts-et-Nevis'},
    { value: 'Sainte-Lucie', label: 'Sainte-Lucie'},
    { value: 'Saint-Marin', label: 'Saint-Marin'},
    { value: 'Saint-Vincent-et-les Grenadines', label: 'Saint-Vincent-et-les Grenadines'},
    { value: 'Saint-Siège', label: 'Saint-Siège'},
    { value: 'Salvador', label: 'Salvador'},
    { value: 'Samoa', label: 'Samoa'},
    { value: 'Sao Tomé-et-Principe', label: 'Sao Tomé-et-Principe'},
    { value: 'Sénégal', label: 'Sénégal'},
    { value: 'Serbie', label: 'Serbie'},
    { value: 'Seychelles', label: 'Seychelles'},
    { value: 'Sierra Leone', label: 'Sierra Leone'},
    { value: 'Singapour', label: 'Singapour'},
    { value: 'Slovaquie', label: 'Slovaquie'},
    { value: 'Slovénie', label: 'Slovénie'},
    { value: 'Somalie', label: 'Somalie'},
    { value: 'Soudan', label: 'Soudan'},
    { value: 'Soudan du Sud', label: 'Soudan du Sud'},
    { value: 'Sri Lanka', label: 'Sri Lanka'},
    { value: 'Suède', label: 'Suède'},
    { value: 'Suisse', label: 'Suisse'},
    { value: 'Suriname', label: 'Suriname'},
    { value: 'Swaziland', label: 'Swaziland'},
    { value: 'Syrie', label: 'Syrie'},
    { value: 'Tadjikistan', label: 'Tadjikistan'},
    { value: 'Taïwan', label: 'Taïwan'},
    { value: 'Tanzanie', label: 'Tanzanie'},
    { value: 'Tchad', label: 'Tchad'},
    { value: 'Thaïlande', label: 'Thaïlande'},
    { value: 'Timor-Oriental', label: 'Timor-Oriental'},
    { value: 'Togo', label: 'Togo'},
    { value: 'Tonga', label: 'Tonga'},
    { value: 'Trinité-et-Tobago', label: 'Trinité-et-Tobago'},
    { value: 'Tunisie', label: 'Tunisie'},
    { value: 'Turquie', label: 'Turquie'},
    { value: 'Turkménistan', label: 'Turkménistan'},
    { value: 'Tuvalu', label: 'Tuvalu'},
    { value: 'Ukraine', label: 'Ukraine'},
    { value: 'Uruguay', label: 'Uruguay'},
    { value: 'Vanuatu', label: 'Vanuatu'},
    { value: 'Venezuela', label: 'Venezuela'},
    { value: 'Vietnam', label: 'Vietnam'},
    { value: 'Yémen', label: 'Yémen'},
    { value: 'Zambie', label: 'Zambie'},
    { value: 'Zimbabwe', label: 'Zimbabwe'},
    { value: 'Antigua-et-Barbuda', label: 'Antigua-et-Barbuda'},
    { value: 'Irak', label: 'Irak'},
    { value: 'Mongolie', label: 'Mongolie'},
    { value: 'Nouvelle-Calédonie', label: 'Nouvelle-Calédonie'},
    { value: 'Palaos', label: 'Palaos'},
    { value: 'Saint-Pierre-et-Miquelon', label: 'Saint-Pierre-et-Miquelon'},
    { value: 'Vatican', label: 'Vatican'},
    { value: 'Terres australes et antarctiques françaises', label: 'Terres australes et antarctiques françaises'},
    { value: 'Wallis-et-Futuna', label: 'Wallis-et-Futuna'},
    { value: 'Cap-Vert', label: 'Cap-Vert'},
    { value: 'Eswatini', label: 'Eswatini'},
    { value: 'Palestine', label: 'Palestine'}
];


export const AGE_OPTIONS = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31'},
    { value: '32', label: '32'},
    { value: '33', label: '33'},
    { value: '34', label: '34'},
    { value: '35', label: '35'},
    { value: '36', label: '36'},
    { value: '37', label: '37'},
    { value: '38', label: '38'},
    { value: '39', label: '39'},
    { value: '40', label: '40'},
    { value: '41', label: '41'},
    { value: '42', label: '42'},
    { value: '43', label: '43'},
    { value: '44', label: '44'},
    { value: '45', label: '45'},
    { value: '46', label: '46'},
    { value: '47', label: '47'},
    { value: '48', label: '48'},
    { value: '49', label: '49'},
    { value: '50', label: '50'},
    { value: '51', label: '51'},
    { value: '52', label: '52'},
    { value: '53', label: '53'},
    { value: '54', label: '54'},
    { value: '55', label: '55'},
    { value: '56', label: '56'},
    { value: '57', label: '57'},
    { value: '58', label: '58'},
    { value: '59', label: '59'},
    { value: '60', label: '60'},
    { value: '61', label: '61'},
    { value: '62', label: '62'},
    { value: '63', label: '63'},
    { value: '64', label: '64'},
    { value: '65', label: '65'},
    { value: '66', label: '66'},
    { value: '67', label: '67'},
    { value: '68', label: '68'},
    { value: '69', label: '69'},
    { value: '70', label: '70'},
    { value: '71', label: '71'},
    { value: '72', label: '72'},
    { value: '73', label: '73'},
    { value: '74', label: '74'},
    { value: '75', label: '75'},
];


export const DESTINATION_COUNTRY_OPTIONS = [
    { value: 'Afrique du Sud', label: 'Afrique du Sud'},
    { value: 'Afghanistan', label: 'Afghanistan'},
    { value: 'Albanie', label: 'Albanie'},
    { value: 'Algérie', label: 'Algérie'},
    { value: 'Allemagne', label: 'Allemagne'},
    { value: 'Andorre', label: 'Andorre'},
    { value: 'Angola', label: 'Angola'},
    { value: 'Arabie saoudite', label: 'Arabie saoudite'},
    { value: 'Argentine', label: 'Argentine'},
    { value: 'Arménie', label: 'Arménie'},
    { value: 'Australie', label: 'Australie'},
    { value: 'Autriche', label: 'Autriche'},
    { value: 'Azerbaïdjan', label: 'Azerbaïdjan'},
    { value: 'Bahamas', label: 'Bahamas'},
    { value: 'Bahreïn', label: 'Bahreïn'},
    { value: 'Bangladesh', label: 'Bangladesh'},
    { value: 'Barbade', label: 'Barbade'},
    { value: 'Bélarus', label: 'Bélarus'},
    { value: 'Belgique', label: 'Belgique'},
    { value: 'Bélize', label: 'Bélize'},
    { value: 'Bénin', label: 'Bénin'},
    { value: 'Bhoutan', label: 'Bhoutan'},
    { value: 'Birmanie', label: 'Birmanie'},
    { value: 'Bolivie', label: 'Bolivie'},
    { value: 'Bosnie-Herzégovine', label: 'Bosnie-Herzégovine'},
    { value: 'Botswana', label: 'Botswana'},
    { value: 'Brésil', label: 'Brésil'},
    { value: 'Brunéi Darussalam', label: 'Brunéi Darussalam'},
    { value: 'Bulgarie', label: 'Bulgarie'},
    { value: 'Burkina Faso', label: 'Burkina Faso'},
    { value: 'Burundi', label: 'Burundi'},
    { value: 'Cameroun', label: 'Cameroun'},
    { value: 'Canada', label: 'Canada'},
    { value: 'Cambodge', label: 'Cambodge'},
    { value: 'Chili', label: 'Chili'},
    { value: 'Chine', label: 'Chine'},
    { value: 'Chypre', label: 'Chypre'},
    { value: 'Colombie', label: 'Colombie'},
    { value: 'Comores', label: 'Comores'},
    { value: 'Congo', label: 'Congo'},
    { value: 'Corée du Nord', label: 'Corée du Nord'},
    { value: 'Corée du Sud', label: 'Corée du Sud'},
    { value: 'Costa Rica', label: 'Costa Rica'},
    { value: 'Côte d’Ivoire', label: 'Côte d’Ivoire'},
    { value: 'Croatie', label: 'Croatie'},
    { value: 'Cuba', label: 'Cuba'},
    { value: 'Danemark', label: 'Danemark'},
    { value: 'Djibouti', label: 'Djibouti'},
    { value: 'Dominique', label: 'Dominique'},
    { value: 'Égypte', label: 'Égypte'},
    { value: 'Émirats arabes unis', label: 'Émirats arabes unis'},
    { value: 'Équateur', label: 'Équateur'},
    { value: 'Érythrée', label: 'Érythrée'},
    { value: 'Espagne', label: 'Espagne'},
    { value: 'Estonie', label: 'Estonie'},
    { value: 'États-Unis d’Amérique', label: 'États-Unis d’Amérique'},
    { value: 'Éthiopie', label: 'Éthiopie'},
    { value: 'Fidji', label: 'Fidji'},
    { value: 'Finlande', label: 'Finlande'},
    { value: 'France', label: 'France'},
    { value: 'Gabon', label: 'Gabon'},
    { value: 'Gambie', label: 'Gambie'},
    { value: 'Géorgie', label: 'Géorgie'},
    { value: 'Ghana', label: 'Ghana'},
    { value: 'Grèce', label: 'Grèce'},
    { value: 'Grenade', label: 'Grenade'},
    { value: 'Guatemala', label: 'Guatemala'},
    { value: 'Guinée', label: 'Guinée'},
    { value: 'Guinée-Bissau', label: 'Guinée-Bissau'},
    { value: 'Guinée équatoriale', label: 'Guinée équatoriale'},
    { value: 'Guyana', label: 'Guyana'},
    { value: 'Haïti', label: 'Haïti'},
    { value: 'Honduras', label: 'Honduras'},
    { value: 'Hong Kong', label: 'Hong Kong'},
    { value: 'Hongrie', label: 'Hongrie'},
    { value: 'Île Maurice', label: 'Île Maurice'},
    { value: 'Îles Marshall', label: 'Îles Marshall'},
    { value: 'Îles Salomon', label: 'Îles Salomon'},
    { value: 'Inde', label: 'Inde'},
    { value: 'Indonésie', label: 'Indonésie'},
    { value: 'Iran', label: 'Iran'},
    { value: 'Irak', label: 'Irak'},
    { value: 'Irlande', label: 'Irlande'},
    { value: 'Islande', label: 'Islande'},
    { value: 'Israël', label: 'Israël'},
    { value: 'Italie', label: 'Italie'},
    { value: 'Jamaïque', label: 'Jamaïque'},
    { value: 'Japon', label: 'Japon'},
    { value: 'Jordanie', label: 'Jordanie'},
    { value: 'Kazakhstan', label: 'Kazakhstan'},
    { value: 'Kenya', label: 'Kenya'},
    { value: 'Kiribati', label: 'Kiribati'},
    { value: 'Kirghizistan', label: 'Kirghizistan'},
    { value: 'Kosovo', label: 'Kosovo'},
    { value: 'Koweït', label: 'Koweït'},
    { value: 'Laos', label: 'Laos'},
    { value: 'Lesotho', label: 'Lesotho'},
    { value: 'Lettonie', label: 'Lettonie'},
    { value: 'Liban', label: 'Liban'},
    { value: 'Libéria', label: 'Libéria'},
    { value: 'Libye', label: 'Libye'},
    { value: 'Liechtenstein', label: 'Liechtenstein'},
    { value: 'Lituanie', label: 'Lituanie'},
    { value: 'Luxembourg', label: 'Luxembourg'},
    { value: 'Macédoine', label: 'Macédoine'},
    { value: 'Madagascar', label: 'Madagascar'},
    { value: 'Malaisie', label: 'Malaisie'},
    { value: 'Malawi', label: 'Malawi'},
    { value: 'Maldives', label: 'Maldives'},
    { value: 'Mali', label: 'Mali'},
    { value: 'Malte', label: 'Malte'},
    { value: 'Maroc', label: 'Maroc'},
    { value: 'Mauritanie', label: 'Mauritanie'},
    { value: 'Mexique', label: 'Mexique'},
    { value: 'Micronésie', label: 'Micronésie'},
    { value: 'Moldavie', label: 'Moldavie'},
    { value: 'Monaco', label: 'Monaco'},
    { value: 'Monténégro', label: 'Monténégro'},
    { value: 'Mozambique', label: 'Mozambique'},
    { value: 'Myanmar', label: 'Myanmar'},
    { value: 'Namibie', label: 'Namibie'},
    { value: 'Nauru', label: 'Nauru'},
    { value: 'Népal', label: 'Népal'},
    { value: 'Nicaragua', label: 'Nicaragua'},
    { value: 'Niger', label: 'Niger'},
    { value: 'Nigéria', label: 'Nigéria'},
    { value: 'Norvège', label: 'Norvège'},
    { value: 'Nouvelle-Zélande', label: 'Nouvelle-Zélande'},
    { value: 'Oman', label: 'Oman'},
    { value: 'Ouganda', label: 'Ouganda'},
    { value: 'Ouzbékistan', label: 'Ouzbékistan'},
    { value: 'Pakistan', label: 'Pakistan'},
    { value: 'Palaos', label: 'Palaos'},
    { value: 'Panamá', label: 'Panamá'},
    { value: 'Papouasie-Nouvelle-Guinée', label: 'Papouasie-Nouvelle-Guinée'},
    { value: 'Paraguay', label: 'Paraguay'},
    { value: 'Pays-Bas', label: 'Pays-Bas'},
    { value: 'Pérou', label: 'Pérou'},
    { value: 'Philippines', label: 'Philippines'},
    { value: 'Pologne', label: 'Pologne'},
    { value: 'Polynésie Française', label: 'Polynésie Française'},
    { value: 'Porto Rico', label: 'Porto Rico'},
    { value: 'Portugal', label: 'Portugal'},
    { value: 'Qatar', label: 'Qatar'},
    { value: 'République centrafricaine', label: 'République centrafricaine'},
    { value: 'République démocratique du Congo', label: 'République démocratique du Congo'},
    { value: 'République dominicaine', label: 'République dominicaine'},
    { value: 'République tchèque', label: 'République tchèque'},
    { value: 'Roumanie', label: 'Roumanie'},
    { value: 'Royaume-Uni', label: 'Royaume-Uni'},
    { value: 'Russie', label: 'Russie'},
    { value: 'Rwanda', label: 'Rwanda'},
    { value: 'Saint-Kitts-et-Nevis', label: 'Saint-Kitts-et-Nevis'},
    { value: 'Sainte-Lucie', label: 'Sainte-Lucie'},
    { value: 'Saint-Marin', label: 'Saint-Marin'},
    { value: 'Saint-Vincent-et-les Grenadines', label: 'Saint-Vincent-et-les Grenadines'},
    { value: 'Saint-Siège', label: 'Saint-Siège'},
    { value: 'Salvador', label: 'Salvador'},
    { value: 'Samoa', label: 'Samoa'},
    { value: 'Sao Tomé-et-Principe', label: 'Sao Tomé-et-Principe'},
    { value: 'Sénégal', label: 'Sénégal'},
    { value: 'Serbie', label: 'Serbie'},
    { value: 'Seychelles', label: 'Seychelles'},
    { value: 'Sierra Leone', label: 'Sierra Leone'},
    { value: 'Singapour', label: 'Singapour'},
    { value: 'Slovaquie', label: 'Slovaquie'},
    { value: 'Slovénie', label: 'Slovénie'},
    { value: 'Somalie', label: 'Somalie'},
    { value: 'Soudan', label: 'Soudan'},
    { value: 'Soudan du Sud', label: 'Soudan du Sud'},
    { value: 'Sri Lanka', label: 'Sri Lanka'},
    { value: 'Suède', label: 'Suède'},
    { value: 'Suisse', label: 'Suisse'},
    { value: 'Suriname', label: 'Suriname'},
    { value: 'Swaziland', label: 'Swaziland'},
    { value: 'Syrie', label: 'Syrie'},
    { value: 'Tadjikistan', label: 'Tadjikistan'},
    { value: 'Taïwan', label: 'Taïwan'},
    { value: 'Tanzanie', label: 'Tanzanie'},
    { value: 'Tchad', label: 'Tchad'},
    { value: 'Thaïlande', label: 'Thaïlande'},
    { value: 'Timor-Oriental', label: 'Timor-Oriental'},
    { value: 'Togo', label: 'Togo'},
    { value: 'Tonga', label: 'Tonga'},
    { value: 'Trinité-et-Tobago', label: 'Trinité-et-Tobago'},
    { value: 'Tunisie', label: 'Tunisie'},
    { value: 'Turquie', label: 'Turquie'},
    { value: 'Turkménistan', label: 'Turkménistan'},
    { value: 'Tuvalu', label: 'Tuvalu'},
    { value: 'Ukraine', label: 'Ukraine'},
    { value: 'Uruguay', label: 'Uruguay'},
    { value: 'Vanuatu', label: 'Vanuatu'},
    { value: 'Venezuela', label: 'Venezuela'},
    { value: 'Vietnam', label: 'Vietnam'},
    { value: 'Yémen', label: 'Yémen'},
    { value: 'Zambie', label: 'Zambie'},
    { value: 'Antigua-et-Barbuda', label: 'Antigua-et-Barbuda'},
    { value: 'Mongolie', label: 'Mongolie'},
    { value: 'Nouvelle-Calédonie', label: 'Nouvelle-Calédonie'},
    { value: 'Saint-Pierre-et-Miquelon', label: 'Saint-Pierre-et-Miquelon'},
    { value: 'Vatican', label: 'Vatican'},
    { value: 'Terres australes et antarctiques françaises', label: 'Terres australes et antarctiques françaises'},
    { value: 'Wallis-et-Futuna', label: 'Wallis-et-Futuna'},
    { value: 'Cap-Vert', label: 'Cap-Vert'},
    { value: 'Eswatini', label: 'Eswatini'},
    { value: 'Palestine', label: 'Palestine'}
];


export const INSURANCE_DURATION_OPTIONS = [
    // { value: '<3', label: 'Moins de 3 mois' },
    // { value: '3-12', label: '3 to 12 months' },
    { value: '12>', label: 'Plus de 12 mois' }
];


export const PAYMENT_FREQUENCY_OPTIONS = [
    { value: 12, label: 'Mensuel' },
    { value: 4, label: 'Trimestriel' },
    { value: 2, label: 'Semestriel' },
    { value: 1, label: 'Annuel' }
];

export const PAYMENT_CURRENCY_OPTIONS = [
    { value: "USD", label: 'USD' },
    { value: "EUR", label: 'EUR' }
];

export const SORT_OPTIONS = [
    { value: "high", label: 'High first' },
    { value: "low", label: 'Low first' }
];
