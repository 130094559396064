import React, { Component } from 'react';
import './ComparesPlans.css';
import {Col, Container, Modal, Row, Spinner, Table} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    calculateFinalPrice,
    renderGuaranteeLevelNameTag,
    renderInsuranceCompanyLogo,
    generateFormattedPrice, detectCurrency, embedHubSpotInitScripts, textInputValidation, emailInputValidation
} from "../../../global-app-elements/global_helper_functions";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { PAYMENT_FREQUENCY_OPTIONS } from "../../../global-app-elements/global_selects_options";
import Select, {components} from "react-select";
import {
    saveInsurancePlanDataForGetPlan,
    changePaymentFrequencyValue,
    fetchInsurancePlanExtendedData,
    multipleFetchingInsurancePlansExtendedData,
    sendGatingDataToEmail,
    sendDataAndGetFile
} from "../../../actions/actions";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PhoneInput from "react-phone-input-2";
import mainPdf from "../../../assets/images/main_pdf.jpg";
import mainPdf2 from "../../../assets/images/main_pdf_2.jpg";
import PdfBackgroundElement from "../../../assets/images/background-element.svg";
import PdfPlane from "../../../assets/images/above-the-fold-bottom-images.svg";
import facebook from "../../../assets/images/icons/facebook.svg";
import instagram from "../../../assets/images/icons/instagram.svg";
import {GAevent} from "../../../global-app-elements/ga-functions";
import Tooltip from "../../GlobalComponents/Tooltip/Tooltip";

const IF_VIEW_PLAN_LEVEL_IS_NON = (<span className="icon-close"></span>);


const cloneDeep = require('clone-deep');
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};





class ComparesPlan extends Component {

    constructor(props) {

        super(props);

        this.state = {
            data_type_for_USA_MSH: 'DANS',
            table_width: 0,
            is_mobile_scroll_listener_active: false,
            is_mobile_horizontal_scroll_ending: false,
            form_validation_error_detected: false,
            show_download_modal: false,
            user_personal_information: {
                contact_information_object: {
                    full_name: null,
                    email: null,
                    phone: null,
                    message: null
                }
            },
            pdfUrl: ''
        };

        this.handleGetPlan = this.handleGetPlan.bind(this);
        this.renderComparePlansAccordionItem = this.renderComparePlansAccordionItem.bind(this);
        this.renderOneColByOneRowCompareTable = this.renderOneColByOneRowCompareTable.bind(this);
        this.renderAllColsByOneRowCompareTable = this.renderAllColsByOneRowCompareTable.bind(this);
        this.handleChangePaymentFrequencyOption = this.handleChangePaymentFrequencyOption.bind(this);
        this.handleChangeDataTypeForUsaMsh = this.handleChangeDataTypeForUsaMsh.bind(this);
        this.renderTopColsOfComparePlansTable = this.renderTopColsOfComparePlansTable.bind(this);
        this.handleHorizontalScrollTableToRight = this.handleHorizontalScrollTableToRight.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.checkTableWidth = this.checkTableWidth.bind(this);
        this.showDownloadModal = this.showDownloadModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleGlobalContactForm = this.handleGlobalContactForm.bind(this);
        this.handleChangeContactInformationFullName = this.handleChangeContactInformationFullName.bind(this);
        this.handleChangeContactInformationEmail = this.handleChangeContactInformationEmail.bind(this);
        this.handleChangeContactInformationPhone = this.handleChangeContactInformationPhone.bind(this);
        this.generatePdf = this.generatePdf.bind(this);

    }


    componentDidMount() {

        this.getInsurancePlansExtendedData();


        // FIXED HEADER DESKTOP//////////////////
        /////////////////////////////////////////
        // слухати скролл модалки compare , і фіксувати/відпускати хедер
        let compare_modal_object = document.getElementsByClassName("modal")[0];


        compare_modal_object.addEventListener('scroll', function(e) {

            let static_table_header_object = document.getElementById("staticTableHeader");
            let sticky_table_header_object = document.getElementById("stickyTableHeader");
            let scroll_to_top_button = document.getElementById("scrollToTopDynamicButton");
            let header_bottom_position_to_viewport = static_table_header_object.getBoundingClientRect().bottom;

            // IF FOR STICKY HEADER
            if ( header_bottom_position_to_viewport <= 0 ) {

                sticky_table_header_object.classList.add("is-visible");

            } else {

                sticky_table_header_object.classList.remove("is-visible");

            }

            // IF FOR BUTTON TO TOP
            if ( ( header_bottom_position_to_viewport + 100 ) <= 0 ) {

                scroll_to_top_button.classList.add("is-visible");

            } else {

                scroll_to_top_button.classList.remove("is-visible");

            }

        });


        // перевизначити ширину фіксованого хедера таблиці , якщо ресайзнули вікно
        window.addEventListener('resize', this.checkTableWidth);

    }


    componentDidUpdate(prevProps, prevState) {

        // перевизначити ширину фіксованого хедера таблиці , якщо щось оновилося
        this.checkTableWidth();


        // Якщо користувач змінив datatype_for_USA_MSH , то треба оновити view plan дані
        if (prevState.data_type_for_USA_MSH !== this.state.data_type_for_USA_MSH) {

            this.getInsurancePlansExtendedData();

        }


        // FIXED HEADER MOBILE//////////////////
        /////////////////////////////////////////
        // слухати скролл модалки compare , і фіксувати/відпускати хедер
        let compare_modal_object = document.getElementById("comparePlansContainer");
        let then = this;

        if ( compare_modal_object && !this.state.is_mobile_scroll_listener_active ) {

            compare_modal_object.addEventListener('scroll', function(e) {

                let static_table_header_object = document.getElementById("staticTableHeader"),
                    sticky_table_header_object = document.getElementById("stickyTableHeader"),
                    compare_table_container = document.getElementsByClassName("comparePlanDataGroup")[0],
                    header_bottom_position_to_viewport = static_table_header_object.getBoundingClientRect().bottom;



                let new_scroll_left = Math.round(e.target.scrollLeft),
                    offset_width = e.target.offsetWidth,
                    scroll_width = e.target.scrollWidth,
                    scroll_progress = parseInt(scroll_width - offset_width);

                if ( new_scroll_left > scroll_progress - 10 ) {

                    then.setState({ is_mobile_horizontal_scroll_ending: true });

                } else {

                    if ( prevState.is_mobile_scroll_listener_active !== then.state.is_mobile_horizontal_scroll_ending ) {

                        then.setState({ is_mobile_horizontal_scroll_ending: false });

                    }


                }





                // IF FOR STICKY HEADER
                if ( header_bottom_position_to_viewport <= 0 ) {

                    sticky_table_header_object.classList.add("is-visible");
                    compare_table_container.classList.add("is-sticky");

                } else {

                    sticky_table_header_object.classList.remove("is-visible");
                    compare_table_container.classList.remove("is-sticky");

                }

            });

            this.setState({ is_mobile_scroll_listener_active: true });

        }

    }


    componentWillUnmount() {

        window.removeEventListener('resize', this.checkTableWidth);

    }


    checkTableWidth() {

        let table_width = document.getElementsByClassName("comparePlanTableBlock")[0];

        if (table_width) {

            if ( table_width.offsetWidth !== this.state.table_width ) {

                this.setState({
                    table_width: table_width.offsetWidth
                });

            }

        }

    }


    handleGetPlan(plan_id) {

        let result_insurance_plans_array = cloneDeep(this.props.result_insurance_plans_array);

        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        this.props.onSelectInsurancePlanDataObject(current_plan_array[0]);

    }


    handleChangePaymentFrequencyOption(selected_option)  {

        this.props.onChangePaymentFrequencyValue(selected_option.value)

    }


    handleChangeDataTypeForUsaMsh(selected_option) {

        if ( selected_option.target.checked ) {

            this.setState({ data_type_for_USA_MSH: 'DANS' });

        } else {

            this.setState({ data_type_for_USA_MSH: 'HORS' });

        }

    }


    handleHorizontalScrollTableToRight() {

        document.getElementById('comparePlansContainer').scrollLeft += 80;

    }


    generatePdf(data_for_sending) {

        let compare_plans_local_array = this.props.compare_plans_server_array;
        let local_plans_local_array = this.props.compare_plans_local_array;

        function addCompanyLogo(companyData) {

            const companyLogos = {
                "April": "https://expat-assurance.com/expat-plans-new/logos/april.svg",
                "Allianz": "https://expat-assurance.com/expat-plans-new/logos/Allianz.png",
                "MSH": "https://expat-assurance.com/expat-plans-new/logos/insu-msh.jpg",
                "HES": "https://expat-assurance.com/expat-plans-new/logos/new henner logo.jpeg",
                "Assur Travel Equité": "https://expat-assurance.com/expat-plans-new/logos/equite%20logo.jpg",
                "Malakoff Humanis": "https://expat-assurance.com/expat-plans-new/logos/malakoff-humanis.svg",
                "Global Health": "https://expat-assurance.com/expat-plans-new/logos/foyer-global-health-logo.png",
                "ACS Expat": "https://expat-assurance.com/expat-plans-new/logos/ACS.png",
                "Assur Travel": "https://expat-assurance.com/expat-plans-new/logos/swiss-life.svg"
            };

            companyData.forEach(company => {
                if (companyLogos[company.company]) {
                    company.logoUrl = companyLogos[company.company];
                }
            });

            return companyData;
        }

        compare_plans_local_array = addCompanyLogo(compare_plans_local_array);

        function removeDiacritics(str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/é/g, 'e');
        }

        function sanitizeKey(key) {
            return removeDiacritics(key)
                .replace(/[\s'’]+/g, '_')
                .toLowerCase();
        }

        function mergeObjects(arr) {
            const result = {};
            function mergeCoverageLevels(levels, res) {
                levels.forEach(level => {
                    const levelKey = `${sanitizeKey(level.coverage_level_to_match)}_${sanitizeKey(level.level_name)}`;
                    if (!res[levelKey]) {
                        res[levelKey] = {
                            coverage_level_to_match: level.coverage_level_to_match,
                            level_name: level.level_name,
                            level_values: {}
                        };
                    }
                    let previousValue = "";

                    for (let valueKey in level.level_values) {
                        let sanitizedValueKey = sanitizeKey(valueKey);

                        if (sanitizedValueKey === "frais_d_accouchement_:_consultations,_soins_pre_et_post-natal,_hospitalisation,_chambre_privee,_frais_de_sejour,_honoraires_medicaux_et_chirurgicaux") {
                            sanitizedValueKey = "frais";
                        }

                        if (!res[levelKey].level_values[sanitizedValueKey]) {
                            res[levelKey].level_values[sanitizedValueKey] = [];
                        }

                        let value = level.level_values[valueKey];
                        if (value === "NON") {
                            value = "-";
                        } else if (value === "HES") {
                            value = "Groupe Henner";
                        } else if (value === "MERGE") {
                            value = previousValue;
                        }
                            previousValue = value;

                        res[levelKey].level_values[sanitizedValueKey].push(value);
                    }
                });
            }

            arr.forEach(item => {
                for (let key in item) {
                    if (item.hasOwnProperty(key)) {
                        const sanitizedKey = sanitizeKey(key);
                        if (key === 'coverage_levels') {
                            if (!result[sanitizedKey]) result[sanitizedKey] = {};
                            mergeCoverageLevels(item[key], result[sanitizedKey]);
                        } else {
                            if (!result[sanitizedKey]) result[sanitizedKey] = [];
                            let value = item[key] === "MERGE" ? " " : item[key];
                            result[sanitizedKey].push(value);
                        }
                    }
                }

            });

            return result;
        }



        function makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        function getAgesString(dependentsArray) {
            if (!Array.isArray(dependentsArray)) {
                return "";
            }

            return dependentsArray.map(dependent => dependent.age).join(', ');
        }

        function formatDate(isoDate) {
            const date = new Date(isoDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        function getCurrentFormattedDate() {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        function addPlanPrices(insureesData, localPlans, currency) {
            if (!insureesData || !Array.isArray(localPlans)) {
                console.error('Invalid input: insureesData must be an object and localPlans must be an array.');
                return insureesData;
            }

            insureesData.plan_prices = localPlans.map(plan => formatPrice(plan.currentPrice, currency));

            return insureesData;
        }

        function formatPrice(price, currency) {
            const roundedPrice = price.toFixed(2);
            const currencySymbol = currency === 'USD' ? '$' : (currency === 'EUR' ? '€' : currency);

            if (currency === 'USD') {
                return `${currencySymbol} ${roundedPrice}`;
            } else {
                return `${roundedPrice} ${currencySymbol}`;
            }
        }

        function filterCoverageLevels(plans, localPlans) {
            const guaranteeLevelImages = {
                "Basic": "https://expat-assurance.com/expat-plans-new/logos/basic.png",
                "Comfort": "https://expat-assurance.com/expat-plans-new/logos/comfort.png",
                "VIP": "https://expat-assurance.com/expat-plans-new/logos/premium.png"
            };

            // Створюємо новий масив для збереження відфільтрованих планів
            const filteredPlans = plans.map((plan, index) => {
                // Копіюємо план, щоб уникнути змін оригінального об'єкта
                const filteredPlan = JSON.parse(JSON.stringify(plan));

                // Отримуємо відповідний локальний план
                const localPlan = localPlans[index];
                const includedSet = new Set(localPlan.coverage_types_included.split('+'));

                // Проходимо по кожному рівню покриття в плані
                filteredPlan.coverage_levels.forEach(level => {
                    // Перевіряємо, чи включений рівень покриття в localPlan
                    if (!includedSet.has(level.coverage_level_to_match)) {
                        for (let key in level.level_values) {
                            if (level.level_values.hasOwnProperty(key)) {
                                level.level_values[key] = '-';
                            }
                        }
                    }
                });

                // Додаємо зображення рівня гарантії
                const guaranteeLevel = localPlan.guarantee_level_EA_displayed;
                // console.log("localPlan : ", localPlan)
                if (guaranteeLevel) {
                    filteredPlan.guarantee_level_image = guaranteeLevelImages[guaranteeLevel] || '';
                }

                return filteredPlan;
            });

            // console.log(filteredPlans)

            return filteredPlans;
        }


// Виклик функції
        compare_plans_local_array = filterCoverageLevels(compare_plans_local_array, local_plans_local_array);


        let mergedObject = mergeObjects(compare_plans_local_array);

        const agesString = getAgesString(this.props.insurees_data.dependents_array);

        let insurees_data = this.props.insurees_data;

        insurees_data.dependents_array = agesString;
        insurees_data.date_of_subscription = formatDate(insurees_data.date_of_subscription);

        mergedObject = addPlanPrices(mergedObject, local_plans_local_array, this.props.currency);



        function convertPaymentFrequency(value, variant) {
            const frequencyMap = {
                12: 'mensuel',
                4: 'trimestriel',
                6: 'semestriel',
                1: 'annuel'
            };

            if (variant === 2) {
                return capitalizeFirstLetter(frequencyMap[value]) || 'невідоме значення';
            } else {
                return frequencyMap[value] || 'невідоме значення';
            }


        }

        function capitalizeFirstLetter(word) {
            if (word.length === 0) return word; // Перевірка на порожній рядок
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }

        const data = {
            template: {
                id: 1078634,
                data: {
                    id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
                    name: this.props.insurees_data.insuree_object.nationality,
                    role: "Developer",
                    email: data_for_sending.user_personal_information.email,
                    insurees_data: insurees_data,
                    contact_information_object: this.state.user_personal_information.contact_information_object,
                    compare_plans_local_array: mergedObject,
                    current_date: getCurrentFormattedDate(),
                    payment_frequency_value: convertPaymentFrequency(this.props.payment_frequency_value, 1),
                    payment_frequency_value_uppercase: convertPaymentFrequency(this.props.payment_frequency_value, 2),
                }
            },
            format: 'pdf',
            output: 'url',
            name: 'Devis Comparatif'
        };

        // console.log(data.template.data);

        this.props.onSendDataAndGetFile(data);
    }


    renderFixedTheadBlock(array_plans_for_compare) {

        let insurees_data = this.props.insurees_data;
        let fix_table_row = [];

        // console.log(array_plans_for_compare)



        array_plans_for_compare.forEach((item_plan, index) => {

            fix_table_row.push(

                <div id="print3" style={{width: this.state.table_width/(array_plans_for_compare.length+1)+'px'}} key={index} className={`fix-head-td fix-head-td-${index+2}`}>
                    {renderGuaranteeLevelNameTag(item_plan.guarantee_level_EA_displayed)}
                    <div className="fix-head-img-block">
                        <span></span>
                        <img src={renderInsuranceCompanyLogo(item_plan.insurance_company)} alt={item_plan.insurance_company} />
                    </div>
                    <span className="planPriceInCompareTable">
                        {
                            generateFormattedPrice(item_plan.currentPrice, this.props.currency)
                        }
                    </span>
                </div>

            );

        });


        return (

            <div id="stickyTableHeader">
                <div style={{width: this.state.table_width/(array_plans_for_compare.length+1)+'px'}} className="fix-head-td fix-head-td-1"></div>
                {fix_table_row}
            </div>

        );

    }


    renderTheadOfComparePlansTable(array_plans_for_compare) {

        let table_row = [];

        array_plans_for_compare.forEach((item_plan, index) => {

            table_row.push(

                <th key={index}>
                    {renderGuaranteeLevelNameTag(item_plan.guarantee_level_EA_displayed)}
                    <img src={renderInsuranceCompanyLogo(item_plan.insurance_company)} alt={item_plan.insurance_company} />
                </th>

            );

        });


        return (

            <>

                <thead id="staticTableHeader">
                <tr>
                    <th>
                        <div className="paymentFrequencyBlock">
                            <label>Fréquence de paiement :</label>
                            <Select
                                // defaultMenuIsOpen={true}
                                value={ (this.props.payment_frequency_value) ? PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value) : null }
                                components={{ DropdownIndicator }}
                                classNamePrefix="insurance-main-select"
                                className="payment-frequency-option"
                                onChange={this.handleChangePaymentFrequencyOption}
                                options={PAYMENT_FREQUENCY_OPTIONS}
                                isSearchable={false}
                            />
                        </div>
                    </th>
                    {table_row}
                </tr>
                </thead>

            </>

        );

    }


    renderTopColsOfComparePlansTable(array_plans_for_compare) {

        let insurees_data = this.props.insurees_data;

        let table_row1 = [];
        let table_row2 = [];
        let table_row3 = [];
        let table_row4 = [];
        let table_row5 = [];
        let details_count = 0;
        let plan_id = "";
        let insurance_company = "";

        array_plans_for_compare.forEach((item_plan, index) => {

            plan_id = item_plan.plan_id;
            insurance_company = item_plan.insurance_company;

            table_row1.push(

                <td key={index}>
                    <span className="planPriceInCompareTable">
                        {
                            generateFormattedPrice(
                                item_plan.currentPrice, this.props.currency)
                        }
                        {insurance_company === 'ACS Expat' ?
                            <Tooltip
                                tooltipId={`coverage_mat_${plan_id}`}
                                tooltipType="light"
                                tooltipEffect="solid"
                                tooltipIconSize={14}
                                tooltipIconTopPosition={2}
                                tooltipIconOffset={5}
                                tooltipContent="L'option maternité est disponible uniquement pour un.e seul.e assuré.e, et sera appliquée à la première personne indiquée dans le comparateur."
                            />
                            : ""
                        }
                    </span>
                    <button
                        className="mainButtonStyle getPlanButton"
                        onClick={this.handleGetPlan.bind(this, item_plan.plan_id)}>Recevoir un devis</button>
                </td>

            );

            table_row2.push(

                <td key={index}>{item_plan.coverage_sum}</td>

            );
            table_row4.push(

                <td key={index}>{item_plan.default_zone}</td>

            );
            table_row5.push(

                <td key={index}>{item_plan.repatriation_service}</td>

            );


            if (item_plan.insurance_company === "MSH" && this.props.insurees_data.destination_country === "États-Unis d’Amérique") {

                details_count++;

                table_row3.push(

                    <td key={index}>
                        <div className="detailGuaranteesTitle">{(this.state.data_type_for_USA_MSH === 'DANS') ? <strong>Dans le réseau de soins</strong> : 'Hors du réseau de soins' }</div>
                        <div className="detailGuaranteesSwitcher">
                            <input
                                id={`switch${index}`}
                                type="checkbox"
                                checked={(this.state.data_type_for_USA_MSH === 'DANS') ? true : false }
                                onChange={this.handleChangeDataTypeForUsaMsh}
                            />
                            <label htmlFor={`switch${index}`}></label>
                        </div>
                    </td>

                );

            } else {

                table_row3.push(

                    <td key={index}></td>

                );

            }



        });


        return (
            <tbody>
            <tr>
                <td>Prix {(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label.toLowerCase()}&nbsp; : </td>
                {table_row1}
            </tr>
            <tr>
                <td>Somme assurée&nbsp;:</td>
                {table_row2}
            </tr>
            <tr>
                <td>Zone de couverture par defaut&nbsp;:</td>
                {table_row4}
            </tr>
            <tr>
                <td>Assistance-Rapatriement&nbsp;:</td>
                {table_row5}
            </tr>
            { (this.props.insurees_data.destination_country === "États-Unis d’Amérique" && details_count > 0 )
                ?
                <tr>
                    <td>Délais d'attente&nbsp;:</td>
                    {table_row3}
                </tr>
                : null }
            </tbody>
        );

    }


    renderOneColByOneRowCompareTable(current_level_values_key, current_level_name, current_coverage_level_to_match, array_plans_for_compare) {

        // дізнаюсь index поточного левела який відмальовуєтся в даний момент
        const getCurrentLevelIndex = function(arr, current_level_values_key) {

            for (let level_index = 0; level_index < arr.length; level_index += 1) {

                if (arr[level_index]['level_key'] === current_level_values_key) {

                    return level_index;

                }

            }

        }


        // разуємо кількість MERGE прапорців під ряд
        const getMergeFlagsAmount = function(arr, current_level_values_key) {

            let current_level_index = getCurrentLevelIndex(arr, current_level_values_key);
            let mErge_flag_count = 1;

            for (let i = current_level_index + 1; i < arr.length; i++) {

                if (arr[i].level_value === "MERGE") {

                    mErge_flag_count++;

                } else {

                    return mErge_flag_count;

                }

            }

            return mErge_flag_count;

        }


        // перевіряємо чи наступний лемент від поточного не MERGE
        const getNextLevelValue = function(arr, key) {

            for (var i = 0; i < arr.length; i++) {

                if (arr[i].level_key === key) {

                    return arr[i + 1] && arr[i + 1].level_value;

                }

            }

        };


        let one_row_array = [];

        // console.log(array_plans_for_compare);

        array_plans_for_compare.forEach((item_plan, index) => {
            // console.log(item_plan.coverage_levels);
            let current_coverage_levels_object = item_plan.coverage_levels.filter(option => option.level_name === current_level_name);
            let current_levels_values_object = current_coverage_levels_object[0].level_values;

            let current_levels_values_array = [];
            // let current_level_index = null;

            // перетворю об*єкт з бекенду в масив , щоб можна було по ньому зручніше бігати
            for (let level_key in current_levels_values_object) {

                current_levels_values_array.push({
                    level_key: level_key,
                    level_value: current_levels_values_object[level_key]
                })

            }


            let next_level_value = getNextLevelValue(current_levels_values_array, current_level_values_key);

            // console.log(item_plan.coverage_types_included);
            // console.log(current_coverage_level_to_match);

            if ( item_plan.coverage_types_included.includes(current_coverage_level_to_match) ) {

                if (current_levels_values_object[current_level_values_key] !== "MERGE") {


                    if (next_level_value === 'MERGE') {


                        let mErge_flags_amount = getMergeFlagsAmount(current_levels_values_array, current_level_values_key);


                        if (current_levels_values_object[current_level_values_key] === "NON") {

                            one_row_array.push(<td key={index} rowSpan={mErge_flags_amount} className="non-value">{IF_VIEW_PLAN_LEVEL_IS_NON}</td>);

                        } else {

                            one_row_array.push(<td key={index} rowSpan={mErge_flags_amount}>{current_levels_values_object[current_level_values_key]}</td>);

                        }

                    } else {

                        if (current_levels_values_object[current_level_values_key] === "NON") {

                            one_row_array.push(<td key={index} className="non-value">{IF_VIEW_PLAN_LEVEL_IS_NON}</td>);

                        } else {

                            one_row_array.push(<td key={index}>{current_levels_values_object[current_level_values_key]}</td>);

                        }

                    }

                }

            } else {

                one_row_array.push(<td key={index} className="non-value">{IF_VIEW_PLAN_LEVEL_IS_NON}</td>);

            }

        });




        return one_row_array;

    }


    renderAllColsByOneRowCompareTable(current_level_name, current_level_values, current_coverage_level_to_match, array_plans_for_compare) {

        let result_levels_array = [];

        for (const level_values_key in current_level_values) {

            result_levels_array.push(

                <tr key={level_values_key}>
                    <td>{level_values_key}</td>
                    {this.renderOneColByOneRowCompareTable(level_values_key, current_level_name, current_coverage_level_to_match, array_plans_for_compare)}
                </tr>

            )

        }



        return result_levels_array;

    }


    renderComparePlansAccordionItem(array_plans_for_compare) {

        let newArr = [];
        let accordions_array = [];
        let base_coverage_levels = array_plans_for_compare[0].coverage_levels;

        // функція для видплення одинакових елементів з масиву
        Array.prototype.unique = function() {
            var a = this.concat();
            for(var i=0; i<a.length; ++i) {
                for(var j=i+1; j<a.length; ++j) {
                    if(a[i] === a[j])
                        a.splice(j--, 1);
                }
            }

            return a;
        };


        // об`єднання одинакових типів coverage_levels різних планів в один масив для подальшої фільтрації
        array_plans_for_compare.forEach((item_plan) => {

            let s = item_plan.coverage_types_included.split('+');
            newArr = newArr.concat(s).unique();


        });


        // вивід одного елемента акордеону в залежності від умов
        base_coverage_levels.forEach((item_coverage_levels, index) => {

            let item_coverage_level_to_match = item_coverage_levels.coverage_level_to_match;

            if ( newArr.includes(item_coverage_level_to_match) ) {

                accordions_array.push(

                    <AccordionItem key={index} className="comparePlansDataGroupItem page-break" uuid={index}>
                        <AccordionItemHeading className="viewPlanDataGroupHead">
                            <AccordionItemButton>
                                {item_coverage_levels.level_name}
                                <div className="accordion-indicator">
                                    <span className="accordion-indicator-title">Réduire</span>
                                    <span className="accordion-indicator-icon"></span>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Table>
                                <tbody>
                                {
                                    this.renderAllColsByOneRowCompareTable(
                                        item_coverage_levels.level_name,
                                        item_coverage_levels.level_values,
                                        item_coverage_levels.coverage_level_to_match,
                                        array_plans_for_compare,
                                    )
                                }
                                </tbody>
                            </Table>
                        </AccordionItemPanel>
                    </AccordionItem>

                )

            } else {

                accordions_array.push(

                    <AccordionItem key={index} className="viewPlanDataGroupItem not-available" uuid={index}>
                        <AccordionItemHeading className="viewPlanDataGroupHead">
                            <AccordionItemButton>
                                {item_coverage_levels.level_name}
                                <div className="accordion-indicator">Hide<span></span></div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Table striped hover>
                                <tbody>
                                </tbody>
                            </Table>
                        </AccordionItemPanel>
                    </AccordionItem>

                )

            }

        });


        return accordions_array;

    }

    formatDate(dateString) {
        const date = new Date(dateString);

        // Додавання 1, тому що getMonth() повертає місяць від 0 до 11
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }


    renderDependentsAges(array){

        let result = ''

        array.forEach((element, index) => {
            if(index !== 0) {
                result = result + element + "," + " "
            }
        });

        result = result.substring(0, result.length - 2);

        return result;

    }

    convertDateFormat(dateString) {
        var parts = dateString.split("/"); // Розділяємо вхідний рядок по символу '/'
        return `${parts[1]}/${parts[0]}/${parts[2]}`; // Змінюємо порядок на день/місяць/рік
    }

    renderComparePlansServerData() {

        let compare_plans_local_array = this.props.compare_plans_local_array;
        let compare_plans_server_array = this.props.compare_plans_server_array;
        let combined_array_of_plans = [];

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        var originalDate = this.formatDate(this.props.insurees_data.date_of_subscription);
        var convertedDate = this.convertDateFormat(originalDate); // '24/03/2024'



        if ( compare_plans_server_array ) {

            // зливаємо лакльний і серверні масиви даних про план в один
            compare_plans_local_array.forEach((item, index) => {

                combined_array_of_plans.push({...item, ...compare_plans_server_array[index]});

            });

            return (

                <>

                    <Row className="viewPlanServerDataRow">
                    </Row>

                    <Row id="print" className="viewPlanServerDataRow comparePlanTableBlock">



                        <Col xs={12} className="tableContainer">

                            {this.renderFixedTheadBlock(combined_array_of_plans)}

                            <Row className="greenTableRow">

                                <Table className="greenTable">
                                    {this.renderTheadOfComparePlansTable(combined_array_of_plans)}
                                    {this.renderTopColsOfComparePlansTable(combined_array_of_plans)}
                                </Table>

                            </Row>

                            <Row>

                                <Accordion
                                    allowMultipleExpanded={true}
                                    allowZeroExpanded={true}
                                    preExpanded={[0, 1, 2, 3, 4]}
                                    className="viewPlanDataGroup comparePlanDataGroup">

                                    {this.renderComparePlansAccordionItem(combined_array_of_plans)}

                                </Accordion>

                            </Row>

                        </Col>

                    </Row>

                </>
            )

        } else {

            return (

                <div className="loader" style={{textAlign: "center", marginTop: "150px"}}>
                    <Spinner animation="border" variant="success" />
                    {/*Loading...*/}
                </div>

            )

        }

    }


    getInsurancePlansExtendedData() {

        if ( this.props.compare_plans_local_array ) {

            let insurees_data = this.props.insurees_data;

            this.props.onMultipleFetchingInsurancePlansExtendedData(this.props.compare_plans_local_array, insurees_data, this.state.data_type_for_USA_MSH,  this.props.insurees_data.destination_country);

        }

    }


    scrollToTop() {

        let compare_modal_object = document.getElementsByClassName("modal")[0];

        compare_modal_object.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }

    handleChangeContactInformationFullName(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    full_name: input_value
                }
            }
        }));

    };


    handleChangeContactInformationEmail(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    email: input_value
                }
            }
        }));

    };


    handleChangeContactInformationPhone(changed_value) {

        let input_value = changed_value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    phone: input_value
                }
            }
        }));

    };

    handleGlobalContactForm() {

        // TODO: Зробити толкову валідацію
        let not_valid_inputs_array = document.querySelectorAll('.download-form .not-valid-input');


        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            let insurees_data = this.props.insurees_data;

            let data_for_sending = {
                user_personal_information: this.state.user_personal_information.contact_information_object,
                insurees_data: insurees_data
            }

            // console.log(data_for_sending)

            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location.href]);
            _hsq.push(["identify",{
                firstname: 'converted in app',
                lastname: 'Download form '  + data_for_sending.user_personal_information.full_name,
                email: data_for_sending.user_personal_information.email,
                phone: data_for_sending.user_personal_information.phone,
                commentaires: data_for_sending.user_personal_information.message ,
                dur_e_du_contrat_souhait_: data_for_sending.insurees_data.insurance_duration,
                adh_rent_cfe: data_for_sending.insurees_data.insuree_has_cfe,
                leadin_pays_de_destination_bc1e211f71cc3f2e8346f8719273fb80: data_for_sending.insurees_data.destination_country,
                leadin_pays_de_nationalitswb_0b819286eb1d5bab2234c31836f1e050: data_for_sending.insurees_data.insuree_object.nationality,
                user_age_from_search: data_for_sending.insurees_data.insuree_object.age,
                dependents_array: JSON.stringify(data_for_sending.insurees_data.dependents_array)
            }]);
            _hsq.push(['trackPageView']);

            this.handleCloseModal();
            GAevent("DownloadFormSubmitted", "comparateur");
            this.generatePdf(data_for_sending)

        }

    }


    printDocument() {
        document.querySelectorAll('.viewPlanServerDataRow')[0].classList.add("removeBoxShadow");
        document.querySelectorAll('.viewPlanServerDataRow')[1].classList.add("removeBoxShadow");

        const doc = new jsPDF('p', 'mm', 'letter');
        // Додаємо першу та другу картинку
        doc.addImage(mainPdf, 'JPEG', 0, 0, 215.9, 279.4);
        doc.addPage();
        doc.addImage(mainPdf2, 'JPEG', 0, 0, 215.9, 279.4);

        let currentPage = 2;


        html2canvas(document.querySelector("#print2")).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg');
            const imgWidth = 215.9; // ширина A4 в мм
            const pageHeight = 279.4; // висота A4 в мм
            let imgHeight = canvas.height * imgWidth / canvas.width;

            // Контроль відступу знизу для колонтитулу

            doc.addPage();
            currentPage++;
            let position = 0;

            // Обмежуємо максимальну висоту зображення, враховуючи відступ для колонтитулу
            doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight );

        });

        html2canvas(document.querySelector("#print")).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg');
            const imgWidth = 215.9; // ширина A4 в мм
            const pageHeight = 279.4; // висота A4 в мм
            let imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight; // Віднімаємо простір для колонтитулу

            doc.addPage();
            currentPage++;
            let position = 0;

            // Обмежуємо максимальну висоту зображення, враховуючи відступ для колонтитулу
            doc.addImage(imgData, 'JPEG', 0, position - 10, imgWidth, imgHeight);
            heightLeft -= pageHeight;


            // Модифікуємо цикл додавання контенту на сторінку
            while (heightLeft >= 25) {
                if(currentPage === 6 || currentPage === 5){
                    position = heightLeft - imgHeight + 35;
                } else {
                    position = heightLeft - imgHeight + 25;
                }
                // console.log(position)
                doc.addPage();
                currentPage++;

                // Зменшуємо висоту контенту, що додається, на висоту колонтитулу
                doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }


            for (let i = 4; i <= currentPage; i++) {
                doc.setPage(i);

                // Встановлення білого кольору для фону
                doc.setFillColor(255, 255, 255); // RGB значення для білого
                // Малюємо прямокутник з білим заповненням на всю ширину сторінки і висоту, достатню для футера
                // Аргументи: x, y, ширина, висота, 'F' для заповнення
                doc.rect(0, pageHeight - 10, imgWidth, 20, 'F');

                // Футер
                doc.setFontSize(9);
                doc.setTextColor(27, 205, 120);
                const footerText = `EXPAT-ASSURANCE.COM`;
                doc.text(footerText, imgWidth / 2 - (doc.getTextWidth(footerText) / 2), pageHeight - 3, { baseline: 'bottom' });
            }

            doc.save('Devis Comparatif.pdf');

            document.querySelectorAll('.viewPlanServerDataRow')[0].classList.remove("removeBoxShadow");
            document.querySelectorAll('.viewPlanServerDataRow')[1].classList.remove("removeBoxShadow");
        });
    }

    renderDownloadModal(){
        let contact_information_object = this.state.user_personal_information.contact_information_object

        return (

            <Modal
                show={this.state.show_download_modal}
                onHide={this.handleCloseModal}
                // backdrop="static"
                keyboard={false}
                centered={true}
                dialogClassName="contact-form-centered-modal download-form">
                <Modal.Header closeButton>
                    <button className="close-modal" onClick={this.handleCloseModal}>
                        <span className="icon-close"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className={`userDataCard contactInformationCard ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>
                        <div className="userDataCardTitle"><span className="icon-message-circle"></span> Faisons un peu mieux connaissance</div>
                        <div className="userDataCardSubTitle">Remplissez le formulaire, et nous vous recontacterons sous 24 heures les jours ouvrés.</div>

                        <Row>

                            <Col xs={12}>
                                <div className={`inputBox ${(textInputValidation(contact_information_object.full_name)) ? "is-valid-input" : "not-valid-input" }`}>
                                    <label>Nom et prénom<span className="red">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Entrez les nom et prénom"
                                        defaultValue={contact_information_object.full_name}
                                        onChange={this.handleChangeContactInformationFullName}
                                    />
                                </div>
                            </Col>

                        </Row>

                        <Row>

                            <Col xs={6}>
                                <div className={`inputBox ${(emailInputValidation(contact_information_object.email)) ? "is-valid-input" : "not-valid-input" }`}>
                                    <label>Adresse email<span className="red">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Entrez votre adresse e-mail"
                                        defaultValue={contact_information_object.email}
                                        onChange={this.handleChangeContactInformationEmail}
                                    />
                                </div>
                            </Col>

                            <Col xs={6}>
                                <div className={`inputBox ${(contact_information_object.phone) ? "is-valid-input" : "not-valid-input" }`}>
                                    <label>Téléphone<span className="red">*</span></label>
                                    <PhoneInput
                                        country={'fr'}
                                        placeholder="+33 1 09 75 83 51"
                                        defaultValue={contact_information_object.phone}
                                        onChange={this.handleChangeContactInformationPhone}
                                    />
                                </div>
                            </Col>

                        </Row>

                        <Row className="bottomRow">
                            <Col xs={9} className="legalCol">
                                <p>
                                    Les données collectées par Expat International Care sont nécessaires afin de vous présenter des produits d’assurance adaptés à votre situation, et faciliter la souscription de votre contrat d’assurance. En cas d’accord de votre part, vos données sont également collectées à des fins de prospection commerciale. Les données suivies d’un (*) sont obligatoires. A défaut, nous ne pourrons valablement traiter votre demande. Pour en savoir plus sur le traitement de vos données et sur vos droits, consultez notre <a href="https://expat-assurance.com/fr/politique-protection-donnees/" target="_blank">Politique en matière de protection des données personnelles</a>.
                                </p>
                            </Col>

                            <Col xs={3}>
                                <button className="mainButtonStyle handleReceiveACall" onClick={this.handleGlobalContactForm}>
                                    Envoyer <span className="icon-big-arrow-right"></span>
                                </button>
                            </Col>

                        </Row>

                    </div>

                </Modal.Body>
            </Modal>

        );

    }

    handleCloseModal() {

        this.setState({
            show_download_modal: false
        });

    }

    showDownloadModal(){
        this.setState({
            show_download_modal: true
        });
    }


    printPageArea(areaID){
        // var printContent = document.getElementById(areaID).innerHTML;
        // var originalContent = document.body.innerHTML;
        // document.body.innerHTML = originalContent;
        window.print();
        // document.body.innerHTML = originalContent;
    }

    downloadPdfFromBase64(base64, filename = 'download.pdf') {
        // Конвертація Base64 строки в бінарні дані

        // console.log(base64)

        const binaryString = window.atob(base64); // Декодування Base64
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        // Перетворення бінарної строки в типізований масив Uint8Array
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Створення Blob об'єкту з типізованого масиву
        const pdfBlob = new Blob([bytes], { type: 'application/pdf' });

        // Генерація URL для Blob об'єкта
        const blobUrl = URL.createObjectURL(pdfBlob);

        // Створення посилання для завантаження
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;

        // Емуляція кліку по посиланню
        document.body.appendChild(link);
        link.click();

        // Видалення посилання після завантаження
        document.body.removeChild(link);

        // Очищення URL, створеного для Blob об'єкту
        URL.revokeObjectURL(blobUrl);
    }


    render() {

        let compare_plans_server_array = this.props.compare_plans_server_array;
        let compare_plans_local_array = this.props.compare_plans_local_array;

        const pdfUrl = this.state.pdfUrl;

        if ( compare_plans_server_array && compare_plans_local_array.length > 0 ) {

            return (

                <div className="viewPlan">

                    <Container id="comparePlansContainer" className="defaultContainer viewPlanContainer comparePlansContainer">

                        <div className="downloadBlock col-md-12">
                            <div className="row">
                                <div className="col-md-5 downloadBlockTitle">Télécharger votre tableau comparatif gratuit</div>
                                <div className="col-md-7">
                                    <div className="downloadButton" onClick={()=>{this.showDownloadModal()}} >Télécharger mon comparatif</div>
                                    {/*<div>*/}
                                    {/*    <button onClick={this.generatePdf}>Generate PDF</button>*/}
                                    {/*    {pdfUrl && <button onClick={() => {this.downloadPdfFromBase64(pdfUrl.response)}}>Download PDF</button>}*/}
                                    {/*</div>*/}
                                    {/*<div className="downloadButton" onClick={()=>{this.printDocument()}} >Télécharger mon comparatif</div>*/}
                                </div>
                            </div>
                        </div>

                        {/*<div className="downloadBlock col-md-12">*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-md-5 downloadBlockTitle">Lorem ipsum dolor sit amet...</div>*/}
                        {/*        <div className="col-md-7">*/}
                        {/*            <div className="downloadButton" onClick={()=>{this.printPageArea('print')}} >Download the comparison table</div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        { this.renderComparePlansServerData() }

                        <div className={`mobileTableNavigation ${(this.state.is_mobile_horizontal_scroll_ending) ? 'is-hiding' : null }`}>
                            {/*<div className="mobileTableNavigationLeft">*/}
                            {/*    <button><span className="icon-thin-arrow-left"></span></button>*/}
                            {/*</div>*/}
                            <div className="mobileTableNavigationRight">
                                <button onClick={this.handleHorizontalScrollTableToRight}><span className="icon-thin-arrow-right"></span></button>
                            </div>
                        </div>

                        { this.renderDownloadModal() }

                    </Container>


                    {/* SCROLL TO TOP BUTTON */}
                    <button onClick={this.scrollToTop} id="scrollToTopDynamicButton">
                        <span className="icon-arrow-up"></span> En haut
                    </button>

                </div>

            )

        } else {

            return (

                <div className="loader" style={{textAlign: "center", marginTop: "150px"}}>
                    <Spinner animation="border" variant="success" />
                    {/*Loading...*/}
                </div>


            )

        }

    }


}

export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        local_view_plan_data: state.view_plan_data.local_plan_data,
        server_view_plan_data: state.view_plan_data.server_plan_data,
        compare_plans_local_array: state.compare_plans_data.local_plans_data,
        compare_plans_server_array: state.compare_plans_data.server_plans_data,
        result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        payment_frequency_value: state.payment_frequency.payment_frequency_value,
        currency: state.currency_options.currency,
    }),
    dispatch => ({
        onSelectInsurancePlanDataObject: (plan_data_object) => {
            dispatch(saveInsurancePlanDataForGetPlan(plan_data_object));
        },
        onChangePaymentFrequencyValue: (new_payment_frequency_value) => {
            dispatch(changePaymentFrequencyValue(new_payment_frequency_value));
        },
        onFetchInsurancePlanExtendedData: (insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country) => {
            dispatch(fetchInsurancePlanExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country));
        },
        onMultipleFetchingInsurancePlansExtendedData: (insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country) => {
            dispatch(multipleFetchingInsurancePlansExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country));
        },
        onSendDataAndGetFile: (data_for_sending) => {

            dispatch(sendDataAndGetFile(data_for_sending));
        }
    })
)(ComparesPlan));
