import {
    CHANGE_FILTER_OPTION, CHANGE_SORTING,
} from "../actions/actions";



let initialState = {
    filters_options_object: {
        type_of_coverage: ['H', 'RC'],
        guarantee_level: ['Basic', 'VIP', 'Comfort'],
        insurance_company: ['April', 'Allianz', 'MSH', 'Henner', 'Assur Travel', "PTI", "Liberty", "Cigna", "Groupe Henner", "Equité Assur travel", "Malakoff Humanis", "Global Health", "ACS Expat"],
        sort: "low"
    }
};




export function filters_options_reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_FILTER_OPTION :

            if ( action.payload.selected_option_check_value ) {

                return {
                    filters_options_object: {
                        ...state.filters_options_object,
                        [`${action.payload.filter_type_name}`]: [...state.filters_options_object[`${action.payload.filter_type_name}`], action.payload.selected_option_name]
                    }
                };

            } else {

                return {
                    filters_options_object: {
                        ...state.filters_options_object,
                        [`${action.payload.filter_type_name}`]: state.filters_options_object[`${action.payload.filter_type_name}`].filter(option => option !== action.payload.selected_option_name)
                    }
                };

            }

        case CHANGE_SORTING:
            return {
                filters_options_object: {
                    ...state.filters_options_object,
                    sort: action.payload
                }
            };

        default:

            return state
    }
}
